
import { Component, Watch, Mixins, Prop, Ref } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";
import { getPhysiqueSuggest, updatePhysiqueSuggest } from "@/api/physique";

import { getPefHomeHealthAllList, updatePefHomeHealth } from "@/api/lung";

import { handleBackendDataWithDefaults } from "@/utils/common";

import RichText from "@/components/rich-text.vue";

@Component({
    components: {
        RichText
    }
})
export default class Physique extends Mixins(loading, tablePagination, Resize) {
  @Ref("qtValidateForm") readonly qtValidateForm!: VForm;

  tableData = [];
  itemTotal = 0;

  dialogVisible = false;
  btnLoading = false;
  formData = {
    id: "",
    resultSuggestion: "",
  };
  editorOption = {
    modules: {
      toolbar: [["bold", { color: [] }], [{ size: [] }]],
    },
  };

  qtDialogVisible = false;
  qtBtnLoading = false;
  qtFormData = {
    id: "",
    title: "",
    zzName: "",
    myPhysique: "",
    reason: "",
    physiqueAnalysis: "",
    preventDisease: "",
    homeHealth: "",
    myPhysiqueM: "",
    reasonM: "",
    physiqueAnalysisM: "",
    preventDiseaseM: "",
    homeHealthM: "",
    myPhysiqueW: "",
    reasonW: "",
    physiqueAnalysisW: "",
    preventDiseaseW: "",
    homeHealthW: "",
    minorityMyPhysique: "",
    minorityReason: "",
    minorityPhysiqueAnalysis: "",
    minorityPreventDisease: "",
    minorityHomeHealth: "",
  };
  qtFormRules = {
    myPhysique: [{ required: true, message: "请输入我的体质", trigger: "blur" }],
    reason: { required: true, message: "请输入形成原因", trigger: "blur" },
    physiqueAnalysis: { required: true, message: "请输入体质解密", trigger: "blur" },
  }

  activeName = "first";
  mounted() {
    this.windowResize(180);
    this.getData();
  }

  getData() {
    this.showLoading();
    getPhysiqueSuggest().then((res) => {
      this.tableData = res.data || [];
    }).finally(() => {
      this.hideLoading();
    })
  }

  // 其他配置
  setQt(row) {
    this.qtDialogVisible = true;
    const { id, symptom, myPhysique, reason, physiqueAnalysis, preventDisease, homeHealth, myPhysiqueM, reasonM, physiqueAnalysisM, preventDiseaseM, homeHealthM, myPhysiqueW, reasonW, physiqueAnalysisW, preventDiseaseW, homeHealthW, minorityMyPhysique, minorityReason, minorityPhysiqueAnalysis, minorityPreventDisease, minorityHomeHealth } = row;
    this.qtFormData.zzName = symptom;
    this.qtFormData.id = row.id;
    this.qtFormData.title = symptom;
    Object.assign(this.qtFormData, { myPhysique, reason, physiqueAnalysis, preventDisease, homeHealth, myPhysiqueM, reasonM, physiqueAnalysisM, preventDiseaseM, homeHealthM, myPhysiqueW, reasonW, physiqueAnalysisW, preventDiseaseW, homeHealthW, minorityMyPhysique, minorityReason, minorityPhysiqueAnalysis, minorityPreventDisease, minorityHomeHealth })
  }

  qtDialogConfirm() {
    this.qtValidateForm?.validate().then(() => {
        this.qtBtnLoading = true;
        const params = { ...this.qtFormData };
        updatePhysiqueSuggest(params).then(() => {
          this.$message.success("操作成功");
          this.getData();
          this.qtDialogClose();
        }).finally(() => {
          this.qtBtnLoading = false;
        })
    })
  }

  qtDialogClose() {
    this.qtDialogVisible = false;
    this.activeName = "first";
    this.qtValidateForm?.resetFields();
    this.qtFormData.zzName = "";
    this.qtFormData.id = "";
    this.qtFormData.title = "";
  }

  // 居家保健
  setJj(row: { id: string; resultSuggestion: string }) {
    this.formData.id = row.id;
    this.formData.resultSuggestion = row.resultSuggestion;
    this.dialogVisible = true;
  }

  dialogClose() {
    this.dialogVisible = false;
    this.btnLoading = false;
    // @ts-ignore
    this.$refs.validateForm?.resetFields();
    this.formData.id = "";
  }

  dialogConfirm() {
    this.btnLoading = true;
    updatePefHomeHealth(this.formData)
      .then((res) => {
        this.$message.success("操作成功");
        this.dialogClose();
        this.getData();
      })
      .catch(() => {
        this.btnLoading = false;
      });
  }
  // @Watch("qtFormData", { deep: true })
  // checkQtFormData() {
  //   // @ts-ignore
  //   this.qtValidateForm?.validateField(["physiqueAnalysis", "reason", "myPhysique"]);
  // }
}
