import { service } from '@/ajax/request'

export const getH5Url = () => {
    return service({
        url: `/device/pef/getPefDescribeUrl`,
        method: 'GET',
    })
}

export const updateH5Url = (params: { url: string }) => {
    return service({
        url: `/device/pef/updatePefDescribeUrl`,
        method: "POST",
        params,
        data: params,
        type: "form"
    })
}

export const getPefHomeHealthAllList = (data: any) => {
    return service({
        url: `/device/pef/getPefHomeHealthAllList`,
        method: "GET",
        data,
    })
}

export const getPefSuggestAllList = (data: any) => {
    return service({
        url: `/device/pef/getPefSuggestAllList`,
        method: "GET",
        data,
    })
}

export const updatePefHomeHealth = (data: any) => {
    return service({
        url: `/device/pef/updatePefHomeHealth`,
        method: "POST",
        params: data,
        data,
        type: "form"
    })
}

export const updatePefSuggest = (data: any) => {
    return service({
        url: `/device/pef/updatePefSuggest`,
        method: "POST",
        params: data,
        data,
        type: "form"
    })
}
