import { service } from '@/ajax/request'

export const getPhysiqueSuggest = () => {
    return service({
        url: `/sysPhysique/sysPhysiqueSuggest/getList`,
        method: 'GET',
    })
}

export const updatePhysiqueSuggest = (params) => {
    return service({
        url: `/sysPhysique/sysPhysiqueSuggest/update`,
        method: 'POST',
        params
    })
}